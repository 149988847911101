import React from "react";

const Footer = () => (
  <footer style={{ background: "#1e1f20", bottom: 0, marginTop: 100}}>
    <div className="container mt-5">
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-center align-items-center mt-5 mb-3">
          <p style={{ fontSize: 14, color: "#fff", fontWeight: 400, textAlign: "center", margin: 0, lineHeight: "18px" }}>Support:&nbsp;</p>
          <a style={{ fontSize: 14,  fontWeight: 400, textAlign: "center", margin: 0, lineHeight: "18px" }} href="mailto:support@immune.im">
            support@immune.im
          </a>
        </div>
        <div className="col-12 pb-5">
          <p style={{ fontSize: 14, color: "#fff", fontWeight: 400, textAlign: "center", margin: 0, lineHeight: "18px" }}>
            © 2019 Immune LLC, <a href="/legal/terms-of-service.pdf">Terms &</a> <a href="/legal/privacy-policy.pdf">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
