import React from "react";

import app_store from "../assets/images/apple.png";
import google_play from "../assets/images/play.png";
import imm_image from "../assets/images/imm_image.png";

class HomePage extends React.Component {
  render() {
    return (
      <main>
        <div className="container">
          <div className="row px-0 px-lg-5 mt-0 mt-lg-5">
            <div className="col-12 col-lg-5">
              <div className="row">
                <div className="col-12 pt-4 pt-lg-5 mt-0 mt-lg-5 mb-3">
                  <h1 style={{ fontSize: 48, lineHeight: "60px", color: "#fff", fontWeight: 500, marginBottom: 20 }}>Become IMMUNE</h1>
                  <p style={{ fontSize: 22, lineHeight: "32px", color: "#fff", fontWeight: 400 }}>Free and secure communications with everyone.</p>
                </div>

                <div className="col-auto">
                  <a href="https://apps.apple.com/ru/app/immune-messenger/id1489335158?l=en" target="_blank">
                    <img src={app_store} className="market_images" />
                  </a>
                </div>
                <div className="col-auto">
                  <a href="https://play.google.com/store/apps/details?id=im.immune.app" target="_blank">
                    <img src={google_play} className="market_images" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 mt-5 mt-lg-0">
              <img src={imm_image} style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default HomePage;
