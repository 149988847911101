import React from "react";

import logo from "./logo-b.png";

const Header = () => (
  <header style={{padding: '35px 0', background: '#1e1f20'}}>
    <div className="container">
      <div className="row px-0 px-lg-5">
        <div className="col-auto d-flex justify-content-start align-items-center">
          <img src={logo} style={styles.logo} />
          <h2 style={{fontSize: 16, top: -3, fontWeight: 500, color: '#fff', lineHeight: '22px', margin: '0 0 0 12px'}}>IMMUNE</h2>
        </div>
      </div>
    </div>
  </header>
);

const styles = {
  logo: {
    borderRadius: 6,
    width: 40,
    height: 40
  }
};

export default Header;
